#page-header {
	padding: 30px 0;
}

.navbar-collapse {
	padding-left: 0px;
	padding-right: 0px;
}

.navbar-brand {
	 display: none;
}

.navbar-default {
	border-top: 0px;
	border-left: 0px;
	border-right: 0px;
	border-bottom: 5px solid #7aa8d9;
	background-color: white;

	.navbar-nav {
		li {
		> a {
			color: #3077be;
			text-transform: uppercase;
			font-weight: 400;
			padding-right: 12px;
			padding-left: 12px;
			margin-right: 2px;
			line-height: 14px;
			padding-top: 8px;
			padding-bottom: 8px;
			border-radius: 5px;
			font-size: 14px;

			&:hover {
				background-color: #eeeeee;
				color: #005580;
			}
		}
	}
	li.active {
			> a {
				background-color: #3077be;
				color: #ffffff;

				&:hover {
					background: #3077be;
					color: #ffffff;
				}
			}
		}
	}


}

.logo {
	img {
		position: absolute;
		top: 0px;
	}
}

.navbar {
	min-height: 60px;
	margin-bottom: 40px;
}

.navbar-btn {
	margin-top: 8px;
	margin-bottom: 8px;
}
 
.navbar-collapse {
	margin-left: auto !important;
	width: 100%;
	padding-top: 14px;
	padding-bottom: 14px !important;


	@media (min-width: 992px) {
		width: auto;
	}
}

.navbar-header {


}

.container>.navbar-collapse, .container>.navbar-header {
	margin-left: 0px;
	margin-right: 0px;
}

.navbar-toggle {
	margin-top: 15px !important;
	margin-left: auto !important;

}

.navbar-header {
	float: none;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}





