.btn {
	border-radius: 8px;

	&-default {
		border-color: 0 1px 1px rgba(255,255,255,0.75);
		border: 1px solid #cccccc;
		color: #333333;
    	text-shadow: 0 1px 1px rgba(255,255,255,0.75);
		background-color: #f5f5f5;
		box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
		background-image: linear-gradient(to bottom,#ffffff,#e6e6e6);
	}
}