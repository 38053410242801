body {
	background: linear-gradient(180deg, rgba(162,198,233,1) 0%, rgba(162,198,233,0) 100%);
	background-repeat: no-repeat;
	background-size: 100% 360px;
}

.blue-bar {
	background-color: #3077be;
	color: #ffffff;
	text-align: right;
	padding: 14px 0;
	font-size: 14px;
	
	a {
		color: #ffffff;
		padding: 3px 10px;
	}

	.blue-menu {
		margin-left: auto;
	}
}

.front {
	.region-content {
		border-top: 1px solid #ddd;
		padding-top: 20px;
	}
}

.banner-right-title {
	color: #2b74bf;
    padding: 5px 0;
    background-color: #d7ebf7;
    text-transform: uppercase;
    font-family: 'Roboto',sans-serif;
	font-weight: 300;
	font-size: 17px;
}


#block-block-3 {
	h2 {
		color: #1d5187;
		font-weight: 300;
		text-transform: uppercase;
		margin: 0;
	}
	ul {
		margin-top: 20px;
		
		li {
			line-height: 1.8em;
		}
	}
}
.form-actions {
	border-top: 2px solid #e5e5e5;
	display: flex;
	padding: 30px 0;

	#edit-next {
		margin-left: auto;
	}
}

.field-widget-options-buttons > .form-type-radios {

	@extend .row;

	.control-label {
		@extend .col-md-6;

		img {
			border: 1px solid #eee;
		}

	}
	
}
.form-type-radios > div {
		@extend .col-md-6;
		padding-left: 50px;

		.control-label {
			float: none !important;
		}
	}


 



#edit-field-present-health {
	.form-type-radios > div {
		float: none;
	}
}

#edit-field-not-working {
	.form-type-radios > div {
		float: none;
	}
}

[id^=edit-field-cieqc-] {
	.form-type-radios > div {
		float: none;
	}
}

[id^=edit-field-ffqk-] {
	.form-type-radios > div {
		float: none;
	}
}

[id^=edit-field-cieqi-] {
	.form-type-radios > div {
		float: none;
	}
}

#edit-actions {
	.btn-success {
		margin-left: 15px;
	}
}

.form-required {
	/*display: none;*/
	color: #f00;
}

.label-photo img {
	max-width: 400px;
}

form .control-label {
	width: 100%;
	display: block;
	margin-top: 20px;
}
 
.form-group {
	width: 100%;
	float: left;
	display: block;
	margin-bottom: 30px;
}

.page-node-add-mtap-test {
	.navbar-header {
		height: 50px;
		.btn {
			display: none;
		}
	}
	.blue-bar {
		#phone {
			display: none;
		}
	}
	#header-menu, .nav, .blue-menu {
		display: none;
	}
	
}

.help-block {
	width: 100% !important;
}

#edit-test-other-rate-daily-living,
#edit-test2-other-rate-sports {
	width: auto;
	display: inline-block;
}


.intensity-worst {
	padding-right: 0px !important;
}

.intensity-labels {
	margin-top: 15px;
	margin-bottom: 60px;
}

.page-portal {

	#edit-tests-gdpi {
		.form-group {
			margin-bottom: 10px;
		}
	}

	#edit-tests-mod {
		input[type=text] {
			width:  auto;
			display: inline-block;
		}
	}

	.gpdi-scale {
		clear: both;
		position: relative;
		margin-bottom: 26px;
	}

	.span-10 {
		position: absolute;
	}

	.span-10:nth-child(1) {
		left: 0%;
		width: 100%;
	}

	.span-10:nth-child(2) {
		left: 20.5%;
	}

	.span-10:nth-child(3) {
		left: 29.9%;
	}

	.span-10:nth-child(4) {
		left: 39.6%;
	}

	.span-10:nth-child(5) {
		left: 49.2%;
	}

	.span-10:nth-child(6) {
		left: 59%;
	}

	.span-10:nth-child(7) {
		left: 68.8%;
	}

	.span-10:nth-child(8) {
		left: 78.5%;
	}

	.span-10:nth-child(9) {
		left: 88.2%;
	}
	.span-10:nth-child(10) {
		left: 98%;
	}

	.span-8:nth-child(1) {
		position: absolute;
		left: 0;
	}

	.span-8:nth-child(2) {
		position: absolute;
		left:10%;
	}

	.intensity-nopain { padding-left: 0px;}
}