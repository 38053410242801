form {
	// general labels
	.control-label {
		font-size: 18px;
		line-height: 1.8;
		font-weight: 300;
		color: #45464a;
	}

	.checkbox,
	.radio {
		.control-label {
			font-size: 16px;
		}
	}

	// general - inputs
	.form-control {
		border: 1px solid #444;
		border-radius: 8px;
	}

	// checkboxes
	.form-checkbox {
		width: 14px;
		height: 14px;
		top: 2px;
	}

	// number inputs
	.form-number {
		width: 100px;
	}

}