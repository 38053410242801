h1 {
	font-size: 38.5px;
	font-weight: 700;
	text-transform: uppercase;
	color: #3077be;

	&.page-header {
		border-bottom: 1px solid #eeeeee;
		margin: 20px 0 30px;
		padding-bottom: 9px;
		margin-top: 0px;
	}
}

h3 {
	font-size: 24.5px;
	margin: 10px 0;
	color: #3077be;
}

h2 {
 font-size: 31.5px;
	&.block-title {
		margin-top: 0px;
	}

	color: #3077be;
}

.front {
.page-header {
		display: none;
	}
}

.well {
	border: transparent;
	background: transparent;
	padding: 0;
}

.navbar-header {
	.btn {
	height: 36px;
	font-size: 14px;
	text-transform: uppercase;
	margin-top: 10px;
	}
}
.page-node-add-mtap-test {
	.page-header {
		display: none;
	}
	form .control-label {
		margin-top: 0px !important;
	}
	.form-radios {
		margin-top: 20px;
	}
}