.full-width {
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 15px;
  padding-right: 15px;

  @media (min-width: 768px) {
    margin-left: calc(-50vw + 360px); // half of 720 (750px - 30px of padding)
    margin-right: calc(-50vw + 360px); // half of 720 (750px - 30px of padding)
    padding-left: calc(50vw - 360px);
    padding-right: calc(50vw - 360px);
  }

  @media (min-width: 992px) {
    margin-left: calc(-50vw + 470px); // half of 940 (970px - 30px of padding)
    margin-right: calc(-50vw + 470px); // half of 940 (970px - 30px of padding)
    padding-left: calc(50vw - 470px);
    padding-right: calc(50vw - 470px);
  }

  @media (min-width: 1200px) {
    margin-left: calc(-50vw + 570px); // half of 1140 (1170px - 30px of padding)
    margin-right: calc(-50vw + 570px); // half of 11400 (1170px - 30px of padding)
    padding-left: calc(50vw - 570px);
    padding-right: calc(50vw - 570px);
  }
}

.full-width-contents {
    margin-left: -15px;
    margin-right: -15px;


  @media (min-width: 768px) {
    margin-left: calc(-50vw + 360px); // half of 720 (750px - 30px of padding)
    margin-right: calc(-50vw + 360px); // half of 720 (750px - 30px of padding)
  }

  @media (min-width: 992px) {
    margin-left: calc(-50vw + 470px); // half of 940 (970px - 30px of padding)
    margin-right: calc(-50vw + 470px); // half of 940 (970px - 30px of padding)
  }

  @media (min-width: 1200px) {
    margin-left: calc(-50vw + 570px); // half of 1140 (1170px - 30px of padding)
    margin-right: calc(-50vw + 570px); // half of 11400 (1170px - 30px of padding)
  }

  &.padded {
  	padding-left: 5%;
  	padding-right: 5%;
  }
}
 

.text-small {
	font-size: 14px; 
	line-height: 22px;
}

.form-label--normalized {
	font-size: 18px;
	font-weight: bold;
	line-height: 1.8;
	color: #232323;
}


.no-margin-bottom {
	margin-bottom: 0;
}



// Bootstrap 5ths
.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.col-xs-5ths {
    width: 20%;
    float: left;
}

@media (min-width: 768px) {
    .col-sm-5ths {
        width: 20%;
        float: left;
    }
}

@media (min-width: 992px) {
    .col-md-5ths {
        width: 20%;
        float: left;
    }
}

@media (min-width: 1200px) {
    .col-lg-5ths {
        width: 20%;
        float: left;
    }
}

.text {
  &-white {
    color: white !important;
  }
}
 



.mb{
  &-auto {
    margin-bottom: auto !important;
  }
  &-0 {
    margin-bottom: 0 !important;
  }
  &-0_5 {
    margin-bottom: 0.5rem !important;
  }
  &-1 {
    margin-bottom: 1rem !important;
  }
  &-1_5 {
    margin-bottom: 1.5rem !important;
  }
  &-2 {
    margin-bottom: 2rem;
  }
  &-3 {
    margin-bottom: 3rem !important;
  }
  &-4 {
    margin-bottom: 4rem !important;
  }
  &-5 {
    margin-bottom: 5rem !important;
  }
  &-6 {
    margin-bottom: 6rem !important;
  }
  &-neg0_5 {
    margin-bottom: -0.5rem !important;
  }
  &-neg1 {
    margin-bottom: -1rem !important;
  }
  &-neg1_5 {
    margin-bottom: -1.5rem !important;
  }
  &-neg2 {
    margin-bottom: -2rem;
  }
  &-neg3 {
    margin-bottom: -3rem !important;
  }
  &-neg4 {
    margin-bottom: -4rem !important;
  }
  &-neg5 {
    margin-bottom: -5rem !important;
  }
  &-neg6 {
    margin-bottom: -6rem !important;
  }
}

.ml {
  &-auto {
    margin-left: auto !important;
  }
  &-0 {
    margin-left: 0 !important;
  }
  &-0_5 {
    margin-left: .5rem !important;
  }
  &-1 {
    margin-left: 1rem !important;
  }
  &-1_5 {
    margin-left: 1.5rem !important;
  }
  &-2 {
    margin-left: 2rem !important;
  }
  &-3 {
    margin-left: 3rem !important;
  }
  &-4 {
    margin-left: 4rem !important;
  }
  &-5 {
    margin-left: 5rem !important;
  }
  &-6 {
    margin-left: 6rem !important;
  }
}

.mr {
  &-auto {
    margin-right: auto !important;
  }
  &-0 {
    margin-right: 0 !important;
  }
  &-0_5 {
    margin-right: .5rem !important;
  }
  &-1 {
    margin-right: 1rem !important;
  }
  &-1_5 {
    margin-right: 1.5rem !important;
  }
  &-2 {
    margin-right: 2rem !important;
  }
  &-3 {
    margin-right: 3rem !important;
  }
  &-4 {
    margin-right: 4rem !important;
  }
  &-5 {
    margin-right: 5rem !important;
  }
  &-6 {
    margin-right: 6rem !important;
  }
}

.my {
  &-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  &-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  &-0_5 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }
  &-1 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  &-1_5 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  &-2 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  &-3 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  &-4 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  &-5 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  &-6 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
}

.mx {
  &-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  &-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  &-0_5 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }
  &-1 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  &-1_5 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  &-2 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  &-3 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  &-4 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
  &-5 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  &-6 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }
}

.mt {
  &-auto {
    margin-top: auto !important;
  }
  &-0 {
    margin-top: 0 !important;
  }
  &-0_5 {
    margin-top: .5rem !important;
  }
  &-1 {
    margin-top: 1rem !important;
  }
  &-1_5 {
    margin-top: 1.5rem !important;
  }
  &-2 {
    margin-top: 2rem !important;
  }
  &-3 {
    margin-top: 3rem !important;
  }
  &-4 {
    margin-top: 4rem !important;
  }
  &-5 {
    margin-top: 5rem !important;
  }
  &-6 {
    margin-top: 6rem !important;
  }
  &-neg0_5 {
    margin-top: -0.5rem !important;
  }
  &-neg1 {
    margin-top: -1rem !important;
  }
  &-neg1_5 {
    margin-top: -1.5rem !important;
  }
  &-neg2 {
    margin-top: -2rem;
  }
  &-neg3 {
    margin-top: -3rem !important;
  }
  &-neg4 {
    margin-top: -4rem !important;
  }
  &-neg5 {
    margin-top: -5rem !important;
  }
  &-neg6 {
    margin-top: -6rem !important;
  }
}

.p {
  &-0 {
    padding: 0 !important;
  }
  &_5 {
    padding: .5rem !important;
  }
  &-1 {
    padding: 1rem !important;
  }
  &-1_5 {
    padding: 1.5rem !important;
  }
  &-2 {
    padding: 2rem !important;
  }
  &-3 {
    padding: 3rem !important;
  }
  &-4 {
    padding: 4rem !important;
  }
  &-5 {
    padding: 5rem !important;
  }
  &-6 {
    padding: 6rem !important;
  }
  &-7 {
    padding: 7rem !important;
  }
}

.px {
  &-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  &-0_5 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }
  &-1_5 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  &-1 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  &-2 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  &-3 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  &-4 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  &-5 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  &-6 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }
  &-7 {
    padding-left: 7rem !important;
    padding-right: 7rem !important;
  }
}

.py {
  &-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  &-0_5 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  &-1_5 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  &-2 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  &-3 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  &-4 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  &-5 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  &-6 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  &-7 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

}

.pb{
  &-0 {
    padding-bottom: 0 !important;
  }
  &-0_5 {
    padding-bottom: .5rem !important;
  }
  &-1 {
    padding-bottom: 1rem !important;
  }
  &-1_5 {
    padding-bottom: 1.5rem !important;
  }
  &-2 {
    padding-bottom: 2rem !important;
  }
  &-3 {
    padding-bottom: 3rem !important;
  }
  &-4 {
    padding-bottom: 4rem !important;
  }
  &-5 {
    padding-bottom: 5rem !important;
  }
  &-6 {
    padding-bottom: 6rem !important;
  }
}

.pt {
  &-0 {
    padding-top: 0 !important;
  }
  &-0_5 {
    padding-top: .5rem !important;
  }
  &-1 {
    padding-top: 1rem !important;
  }
  &-1_5 {
    padding-top: 1.5rem !important;
  }
  &-2 {
    padding-top: 2rem !important;
  }
  &-3 {
    padding-top: 3rem !important;
  }
  &-4 {
    padding-top: 4rem !important;
  }
  &-5 {
    padding-top: 5rem !important;
  }
  &-6 {
    padding-top: 6rem !important;
  }
}

.pr {
  &-0 {
    padding-right: 0 !important;
  }
  &-0_5 {
    padding-right: .5rem !important;
  }
  &-1 {
    padding-right: 1rem !important;
  }
  &-1_5 {
    padding-right: 1.5rem !important;
  }
  &-2 {
    padding-right: 2rem !important;
  }
  &-3 {
    padding-right: 3rem !important;
  }
  &-4 {
    padding-right: 4rem !important;
  }
  &-5 {
    padding-right: 5rem !important;
  }
  &-6 {
    padding-right: 6rem !important;
  }
  &-7 {
    padding-right: 6rem !important;
  }
}

.pl {
  &-0 {
    padding-left: 0 !important;
  }
  &-0_5 {
    padding-left: .5rem !important;
  }
  &-1 {
    padding-left: 1rem !important;
  }
  &-1_5 {
    padding-left: 1.5rem !important;
  }
  &-2 {
    padding-left: 2rem !important;
  }
  &-3 {
    padding-left: 3rem !important;
  }
  &-4 {
    padding-left: 4rem !important;
  }
  &-5 {
    padding-left: 5rem !important;
  }
  &-6 {
    padding-left: 6rem !important;
  }
}

 